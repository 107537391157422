<template>
  <base-section
    id="csr-carousel"
    class="pt-0"
  >
    <v-container>
      <v-carousel
        hide-delimiters
        cycle
        :height="$vuetify.breakpoint.smAndDown ? 250 : 650"
      >
        <v-carousel-item
          v-for="n in 5"
          :key="n"
          :src="require(`@/assets/CSR-${n}.jpg`)"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCsrCarousel',
  }
</script>
